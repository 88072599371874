// 文档: https://cloud.tencent.com/document/product/436/11459
import COS from "cos-js-sdk-v5";
import md5 from "js-md5";
import { generateKeys } from "@/api/common";
import { Message } from "element-ui";
let TempKeys = {
  muid: "",
  region: "",
  bucket: "",
  startTime: 0,
  expiredTime: 0,
  credentials: {
    sessionToken: "",
    tmpSecretId: "",
    tmpSecretKey: "",
  },
};

const getExt = (path) => {
  const re = /\.\w+$/;
  const result = path.match(re);
  if (result === null) {
    return "";
  }
  return result[0];
};
/*
  option  文件  callback  回调   customkey 区分文件夹
*/
const uploadFile = async (option, callback, customkey = "files") => {
  await generateKeys("").then((res) => {
    const { data } = res;
    if (data.bucket) {
      TempKeys = data;
      // console.log(TempKeys,"有没有")
    } else {
      //message.error(data.message)
    }
  });

  const cos = new COS({
    SecretId: TempKeys.credentials.tmpSecretId,
    SecretKey: TempKeys.credentials.tmpSecretKey,
    XCosSecurityToken: TempKeys.credentials.sessionToken,
  });

  // 随机化名字
  const filename =
    md5(TempKeys.muid + Date.now() + Math.random()) + getExt(option.file.name);
  try {
    cos.sliceUploadFile(
      {
        Bucket: TempKeys.bucket,
        Region: TempKeys.region,
        Key: customkey + "/" + filename,
        Body: option.file,
        ProgressInterval: 300,
      },
      function (err, data) {
        if (data.statusCode == 200) {
          Message.success("上传成功");
          callback(filename, option, data);
        } else {
          Message.error("上传失败");
        }
      }
    );
  } catch (err) {
    throw err;
  }
};

export { uploadFile, getExt };
